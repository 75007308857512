<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <!--        <left-bar-comp>-->
        <!--            <sports-left-bar-comp></sports-left-bar-comp>-->
        <!--        </left-bar-comp>-->

        <div class="content_section">
            <div class="content">
                <sub-title>출금</sub-title>

                <div class="main_panel">

                    <div class="re_cont2">
                      <div class="c1" style="">예금주</div>
                      <div class="c1">은행</div>
                      <div class="c1">계좌번호</div>
                    </div>
                  <div class="re_cont2">
                    <div class="c2" style="">{{$store.state.userInfo.beneficiary}}</div>
                    <div class="c2">{{$store.state.userInfo.bank}}</div>
                    <div class="c2">{{$store.state.userInfo.acNo}}</div>
                  </div>
                  <div class="re_cont">
                    <div class="c1">
                      보유머니
                    </div>
                    <div class="c2" style="">
                      <span>{{$store.state.userInfo.cash|comma}}</span>
                    </div>
                  </div>

                    <div class="re_cont">
                        <div class="c1">
                            금액
                        </div>
                        <div class="c2" style="">
                            <div style="display: flex;justify-content: flex-start;align-items: center;padding: 10px 0">
                                <input type="text" v-model="applyAmount" @input="setComma" class="re_input"
                                       placeholder="출금금액"
                                       style="">
                                <button type="button" class="btn-re2" @click="changeMoney(0)" style="margin-left: 6px">
                                    삭제
                                </button>
                            </div>

                        </div>
                    </div>
                               <div class="re_cont">
                                   <div class="c1">
                                       빠른입력
                                   </div>
                                   <div class="c2" style="">
                                       <div style="display: flex; justify-content: flex-start;width: 100%;padding: 0px 0;flex-wrap: wrap">
                                           <button type="button" class="btn-re" @click="changeMoney(10000)">
                                               1만원
                                           </button>
                                           <button type="button" class="btn-re" @click="changeMoney(30000)">
                                               3만원
                                           </button>
                                           <button type="button" class="btn-re" @click="changeMoney(50000)">
                                               5만원
                                           </button>
                                           <button type="button" class="btn-re" @click="changeMoney(100000)">
                                               10만원
                                           </button>
                                           <button type="button" class="btn-re" @click="changeMoney(500000)">
                                               50만원
                                           </button>
                                           <button type="button" class="btn-re" @click="changeMoney(1000000)">
                                               100만원
                                           </button>

                                       </div>
                                   </div>
                               </div>
                    <div class="rec_buttons" style="">
                        <button class="b1" @click="exchange">출금신청
                        </button>
                    </div>
                </div>
                <div style="width: 100%;text-align: right">
                    <span style="background-color: #ffa604;padding: 5px 10px;margin-bottom: 10px;color: #fff9ff;border-radius: 3px;cursor: pointer"
                          @click="deleteAll">전체삭제</span>
                </div>
                <div class="re_history">
                    <table cellpadding="0" cellspacing="0" border="0">
                        <colgroup>
                            <col width="27%">
                            <col width="27%">
                            <col width="27%">
                            <col width="15%">
                        </colgroup>
                        <thead>
                        <tr>
                            <th>시간</th>
                            <th>금액</th>
                            <th @click="refreshData()" style="cursor: pointer">상태</th>
                            <th></th>
                        </tr>
                        <tr v-for="(item,indx) in exchangeList" :key="indx">
                            <td>{{item.createTime|datef('MM/DD HH:mm')}}</td>
                            <td>{{item.amount|comma}}</td>
                            <td>
                                <span v-if="item.status === sportsConst.APPLY_STATUS_WAITING">미확인</span>
                                <span v-if="item.status === sportsConst.APPLY_STATUS_CHECKING">처리중</span>
                                <span v-if="item.status === sportsConst.APPLY_STATUS_COMPLITE">완료</span>
                                <span v-if="item.status === sportsConst.APPLY_STATUS_CANCEL"
                                      style="color: red">실패</span>
                            </td>
                            <td>
                  <span
                          style="display: inline-block;width: 50px; height: 20px; line-height: 20px;text-align: center;cursor: pointer;background-color: #ffa604;border-radius: 5px"
                          v-if="item.status !== sportsConst.APPLY_STATUS_WAITING && item.status
                        !== sportsConst.APPLY_STATUS_CHECKING" @click="deleteById(item.id)">
                      삭제
                  </span>

                            </td>
                        </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- right 메뉴 -->
        <!--<right-bar-comp>
             <user-info-comp slot="userinfo"></user-info-comp>
             <right-buttons-comp slot="btns"></right-buttons-comp>
             <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
             <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
         </right-bar-comp>-->

        <foot-comp></foot-comp>

    </div>


</template>

<script>
    import SubTitle from "../../components/SubTitle";
    import RightBarComp from "../../components/RightBarComp";
    import {deleteAllApply, deleteApply, exchangeCash, getApplyCashList, rechargeCash} from "../../network/userRequest";
    import sportsConst from "../../common/sportsConst";
    import LeftBarComp from "../../components/LeftBarComp";
    import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
    import TopbarComp from "../../components/TopbarComp";
    import FootComp from "../../components/FootComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import {getAccountBalance, subjectMemberCasinoCash} from "../../network/casinoHonorRequest";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    import {postionMixin} from "../../common/mixin";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";

    export default {
        name: "Exchange",
        mixins: [postionMixin],
        components: {
            SportsBetCartComp,
            RightButtonsComp,
            UserInfoComp,
            SportsLeftBarComp,
            RightBarBannerComp, FootComp, TopbarComp, LeisureLeftComp, LeftBarComp, RightBarComp, SubTitle
        },
        data() {
            return {
                sportsConst,
                applyCash: {
                    amount: 0,
                    extractPasswd: ''
                },
                applyAmount: '',
                search: {applyType: sportsConst.APPLY_TYPE_EXCHANGE},
                exchangeList: [],
                casinocash: 0,
                position: "출금",
            }
        },
        methods: {
            setComma() {
                if (this.applyAmount == '') {
                    this.applyAmount = ''
                } else {
                    this.applyAmount = this.$root.$options.filters.comma(this.applyAmount)
                }
            },
            changeMoney(cash) {
                if (cash === 0) {
                    this.applyAmount = '';
                } else {
                    let c = this.applyAmount == ''  ? 0 : this.applyAmount.replaceAll(',', '')
                    this.applyAmount = this.$root.$options.filters.comma(parseInt(c) + parseInt(cash));

                }
            },
            exchange() {
                this.applyCash.amount = this.applyAmount.replaceAll(',', '');
                this.$swal({
                    title: this.applyCash.amount + '원 출금신청',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    this.betInfo = {selectedVals: []}
                    if (res.value) {
                        this.$store.commit(RECEIVE_SHOW_LOADING)
                        exchangeCash(this.applyCash).then(res => {
                            this.$store.commit(RECEIVE_HIDE_LOADING)
                            if (res.data.success) {
                                this.$swal({
                                    title: '출금신청 완료',
                                    type: 'success',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                                this.$store.dispatch('actionUserInfo')
                                this.applyAmount = ''
                                this.applyCash.amount = 0
                                this.initExchangeList()
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })
            },

            initExchangeList() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                getApplyCashList(this.search).then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    if (res.data.success) {
                        this.exchangeList = res.data.data
                    }
                })
            },
            refreshData() {
                this.initExchangeList()
                this.$bus.$emit('applyRequestSuccess')
            },
            deleteById(id) {
                this.$swal({
                    title: '출금 삭제',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    if (res.value) {
                        deleteApply(id).then(res => {
                            if (res.data.success) {
                                this.initExchangeList()
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })
            },
            deleteAll(id) {
                this.$swal({
                    title: '전체 삭제',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    if (res.value) {
                        deleteAllApply(sportsConst.APPLY_TYPE_EXCHANGE).then(res => {
                            if (res.data.success) {
                                this.initExchangeList()
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })
            },
            getCasinoBalance() {
                getAccountBalance().then(res => {
                    this.$set(this.$store.state.userInfo, 'casinocash', res.data.data)
                })
            }
        },
        created() {
            //this.getCasinoBalance()
            this.initExchangeList()
        },
        mounted() {
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/base.css");
    @import url("../../assets/css/common.css");
    @import url("../../assets/css/style.css");
    @import url("../../assets/css/media.css");
    @import url("../../assets/css/sports.css");
    @import url("../../assets/css/reex.css");
</style>